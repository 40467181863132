import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAnchorNav,
  CFaqList,
  LPlan,
  CBtnList,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: <>terms and conditions</>,
            ja: '規約・約款',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/agreement_terms/kv.png',
              },
              imgSp: {
                src: '/assets/images/agreement_terms/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '規約・約款',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mtExLarge u_mbLarge p_agreement_terms">
        <LWrap>
          <div className="agreement_terms">
            <div className="agreement_terms_content">
              <CBtnList
                data={[
                  {
                    label: '宿泊約款',
                    link: {
                      href: '/assets/files/pdf/202402_yakkan.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
                exClass="u_mbSmall"
              />
              <CBtnList
                data={[
                  {
                    label: 'サイト利用規約',
                    link: {
                      href: '/agreement_terms/site/',
                    },
                    icon: 'arrow',
                    color: 'borderTheme',
                  },
                ]}
                exClass="u_mbSmall"
              />
              <CBtnList
                data={[
                  {
                    label: 'プライバシーポリシー',
                    link: {
                      href: 'https://www.royalparkhotels.co.jp/privacy/',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
                exClass="u_mbSmall"
              />
            </div>
            <div className="agreement_terms_content">
              <CBtnList
                data={[
                  {
                    label: 'ソーシャルメディアポリシー',
                    link: {
                      href: 'https://www.royalparkhotels.co.jp/social/',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
                exClass="u_mbSmall"
              />
              <CBtnList
                data={[
                  {
                    label: '特定商取引に関する法律に基づく表示',
                    link: {
                      href: 'https://www.royalparkhotels.co.jp/transaction/',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
                exClass="u_mbSmall"
              />
            </div>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
